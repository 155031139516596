




















































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import ConfirmDelete from "../../../components/ConfirmDelete.vue";
import {  Contacts, ICity } from "../../../types/person/Contacts";
import { IContactPhone } from "../../../types/person/IContactPhone";
interface Phone {
  id: string;
  name: string;
}
@Component({
  components: { ConfirmDelete },
})
export default class ContactPhoneInput extends Request {
  @Prop() entity: Contacts;
  @Prop() person: Contacts;
  @Prop() editorUserId: number;
  cityList: ICity[] = [];
  list: IContactPhone[] = [];
  alias: string = "";
  cityId: number = null;
  isMain: boolean = false;
  phoneNumber: string = "";
  deletePath: string = "";
  ddi: string = "";
  cityPhoneCode: string = "";
  phone: string = "";
  closeDialog() {
    this.$emit("closeDialog", false);
  }
  async nextStep() {
    this.list.map((phone) => {
      phone.editorUserId = this.editorUserId;
      phone.isMain === true ? (phone.isMain = true) : (phone.isMain = true);
    });
    this.person.personContactPhones = this.list;
    this.$emit("person-with-phone", this.person);
    this.$emit("nextStep", 1);
  }
  backStep() {
    this.$emit("backStep", -1);
  }
  typePhone: Phone[] = [
    {
      id: "C",
      name: "Comercial",
    },
    {
      id: "P",
      name: "Particular",
    },
  ];
  addComponent() {
    this.list.push({
      alias: this.alias,
      phoneNumber: this.phoneNumber,
      isMain: this.isMain,
      ddi: this.ddi,
      cityPhoneCode: this.cityPhoneCode,
    });
  }
  handleClick(indexElement, model) {
    for (let index = 0; index < this.list.length; index++) {
      if (model === true) {
        this.list[index].isMain = false;
        this.list[indexElement].isMain = true;
      } else {
        this.list[index].isMain = null;
        this.list[indexElement].isMain = true;
      }
    }
  }
  onInput(_formattedNumber: any, { number, valid, country }) {
    valid;
    if (number.input.length > 2) {
      this.list.map((item) => {
        if (item.phone) {
          item.phoneNumber = item.phone;
          item.ddi = "+" + country.dialCode;
          item.cityPhoneCode = item.phoneNumber.substring(1, 3);
        }
      });
    }
  }
  async mounted() {
    const cities = await this.get(this.paths.cityPath);
    this.cityList = cities[0];

    if (this.entity.personContactPhones) {
      this.entity.personContactPhones.map((item) => {
        this.list.push(item.contactPhone);
      });
    }
  }
}
