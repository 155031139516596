



















































import { Component, Prop, Watch } from "vue-property-decorator";
import Request from "../../services/request";
import { mapGetters } from "vuex";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import { IDataUser } from "../../types/chat/data-area";
import {IUser} from "../../types/User";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ActiveDialog extends Request {
  @Prop() readonly type: string;
  @Prop() readonly conversation: IAttendencyList;
  @Prop() drawerSearch: boolean;
  drawer:boolean = false;
  localEntity: IDataUser = {};
  getUser!: () => IUser;
  searchMessage: string = "";
  user:IUser= {};
  messageList: any = [];

  load(): void {
    if (this.conversation.contact) {
      this.localEntity = { ...this.conversation.contact };
    }
  }
  mounted() {
    this.load();
  }

  updateDrawerSearch() {
    this.drawerSearch === true
      ? this.$emit("drawerSearch", false)
      : this.$emit("drawerSearch", true);
  }
  @Watch("searchMessage")
  async constactList(val) {
    if (val.length >= 3) {
      const result = (this.messageList = await this.get(
        this.paths.searchMessagesPath +
          `contactId=${this.localEntity.id}&skip=1&take=10&search=${val}`
      ));
      this.messageList = result.result;
      this.messageList.map((message) => {
        message.message = message.message.replace(/"/g, "");
      });
      return this.messageList;
    } else if (val.length < 3) {
      return (this.messageList = []);
    }
  }
  highlightText(text) {
    return text.replaceAll(
      this.searchMessage,
      `<span class="highlight">${this.searchMessage}</span>`
    );
  }

  selectedMessage(message) {
    return this.$emit("message-to-search", message);
  }
}
