










































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import { Contacts } from "../../../types/person/Contacts";
import { translate } from "../../../plugins/i18n";
@Component({
  components: {},
})
export default class PersonInput extends Request {
  @Prop() readonly entity: Contacts;
  @Prop() readonly editorUserId: number;
  listMarital: any[] = [];
  listMilitary: any[] = [];
  status: any[] = [];
  personType: any = [];
  localEntity: any = {};
  async nextStep() {
    this.localEntity.editorUserId = this.editorUserId;

    const person = this.localEntity;
    this.$emit("nextStep", 1);
    return this.$emit("person", person);
  }
  closeDialog() {
    this.$emit("closeDialog", false);
  }
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async mounted() {
    this.load();
    const maritalStatus = await this.get(this.paths.maritalPath);

    this.listMarital = maritalStatus[0];

    const military = await this.get(this.paths.militaryPath);

    this.listMilitary = military[0];

    const personTpyeList = await this.get(this.paths.personTypePath);
    this.personType = personTpyeList[0];
    const listStatus = await this.get(this.paths.statusPath);

    this.status = listStatus[0];
  }

  partnerList: any[] = [
    {
      id: 1,
      name: "Sim",
    },
    {
      id: 2,
      name: "Não",
    },
  ];
  isMilitary: any[] = [
    {
      id: "S",
      name: "Sim",
    },
    {
      id: "N",
      name: "Não",
    },
  ];
  listGender: any[] = [
    {
      id: "M",
      name: `${translate("male")}`,
    },
    {
      id: "F",
      name: `${translate("female")}`,
    },
    {
      id: "B",
      name: `${translate("notSay")}`,
    },
  ];

  get missingName() {
    return !this.localEntity.firstName;
  }

  get nextDisabled() {
    return this.missingName;
  }
}
