











































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { Contacts } from "../../types/person/Contacts";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import Stepper from "./Stepper.vue";
import { HeaderItem } from "../../types/headeritem";
import { Breadcrumb } from "../../types/breadcrumb";
import ConfirmDelete from "../../components/ConfirmDelete.vue";

@Component({
  components: {
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    // ContactsInputDisabled,
    // ContactsHistoric,
    Stepper,
  },
})
export default class ContactsList extends Request {
  title: string = `${translate("contacts")}`;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("contacts")}`,
      disabled: true,
    },
  ];

  deletePath: string = "";
  tagStatus: string = "status";
  header: HeaderItem[] = [
    { text: `${translate("firstName")}`, align: "start", value: "firstName" },
    {
      text: `${translate("phoneNumber")}`,
      align: "start",
      value: "personContactPhones[0].contactPhone.phoneNumber",
    },
    {
      text: `${translate("email")}`,
      align: "start",
      value: "personContactEmail[0].contactEmail.email",
    },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-contacts",
    titlePage: `${translate("contacts")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: Contacts[] = [];
  async loadData() {
    this.deletePath = this.paths.personPath;
    const result = await this.get(this.paths.personGetComplete);
    this.list = result[0];
  }
  async mounted() {
    this.loadData();
  }
}
