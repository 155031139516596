












































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { mapGetters } from "vuex";
import { io } from "socket.io-client";
import env from "../../services/env";
import { paths } from "../../services/apiPaths";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import {IUser} from "../../types/User";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class PendingAttendencyList extends Request {
  @Prop() readonly conversation: IAttendencyList[];
  @Prop() readonly loadData: () => void;
  @Prop() rerenderNotification: any;
  @Prop() rerender: any;
  @Prop() playSound: any;
  getUser!: () => IUser;
  user:IUser= {};
  socketWhatsappUrl = `${env.protocol}${env.socketWhatsapp}`;
  socketWhatsapp = io(this.socketWhatsappUrl, {
    path: `${paths.socketWhatsappPath}`,
    transports: ["websocket", "polling"],
    reconnection: true,
  });
  dialog:boolean = false;
  editorUserId: number = null;
  currentattendency = {};
  selected: boolean = null;
  async mounted() {
    this.user = this.getUser();
    this.editorUserId = this.user.id;
  }
  openDialog(attendency) {
    this.dialog = true;
    this.currentattendency = attendency;
  }
  async backToAttendency(conversation) {
    const result = await this.patch(
      this.paths.attendencyPath + conversation.attendency.id,
      {
        attendencyStatus: "2",
        editorUserId: this.editorUserId,
      }
    );
    this.loadData();
    this.dialog = false;
    this.$emit("back-to-attendency", 0);
    return result;
  }
  resolveChatIcon(iconType) {
    switch (iconType) {
      case "C":
        return "mdi-chat";
      case "W":
        return "mdi-whatsapp";
      case "T":
        return "mdi-telegram";
      default:
        throw new Error("Invalid icon type: " + iconType);
    }
  }
  created() {
    this.listeningSocketWhatsapp();
  }

  async listeningSocketWhatsapp() {
    this.socketWhatsapp.on("connect", () => {
      this.socketWhatsapp.on("whatsapp", async (message) => {
        const { protocolNumber } = message;

        for (const item of this.conversation) {
          if (item.attendency.protocolNumber === protocolNumber) {
            const result = await this.patch(
              this.paths.attendencyPath + item.attendency.id,
              {
                attendencyStatus: 2,
                editorUserId: this.user.id,
              }
            );
            this.loadData();

            this.$emit("clear-currenct-attendency", {});
            this.$emit("back-to-attendency", 0);
            this.playSound();
            return result;
          }
        }
      });
    });
  }
}
