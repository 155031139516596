


























































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { mapGetters } from "vuex";
import moment from "moment";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import {IUser} from "../../types/User";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class PendingAttendency extends Request {
  @Prop() conversation: IAttendencyList;
  @Prop() loadData: () => void;
  @Prop() type: boolean;
  @Prop() fromMe: any;
  @Prop() drawerHistory: boolean;
  getUser!: () => IUser;
  dialog: boolean = false;
 
  user:IUser= {};
  protocols = [];
  updateDrawerHistory() {
    this.drawerHistory === true
      ? this.$emit("drawerHistory", false)
      : this.$emit("drawerHistory", true);
  }
  async mounted() {
    this.user = this.getUser();
    const protocols = await this.get(
      this.paths.protocolByContactIdPath +
        this.conversation.attendency.contactId
    );
    this.protocols = protocols;
    this.protocols.map((item) => {
      item.createdAt = moment(item.createdAt).format("DD/MM/YYYY");
     
    });
  }
  async loadDetails(item) {
    const messages = await this.get(
      this.paths.messageByProtocolPath + item.protocolNumber
    );
    this.protocols.map((item) => {
      item.createdAt = moment(item.createdAt).format("DD/MM/YYYY");
      item.messages = messages;
      item.messages.map((message) => {
        message.message = message.message.replace(/"/g, "");
        if (message.userName === this.user.firstName) {
          this.fromMe[message.userName] = true;
        } else {
          this.fromMe[message.userName] = false;
        }
      });
    });
  }
  async onSave(conversation) {
    const result = await this.patch(
      this.paths.attendencyPath + conversation.attendency.id,
      {
        attendencyStatus: 7,
        editorUserId: this.user.id,
      }
    );
    this.loadData();
    this.dialog = false;
    this.$emit("clear-currenct-attendency", {});
    return result;
  }

  resolveContactName(contact) {
    if (typeof contact === "string") {
      const newContact = JSON.parse(contact);
      if (newContact.firstName) {
        return (
          newContact.firstName +
          `${newContact.lastName ? newContact.lastName : ""}`
        );
      }
    } else if (contact.length && contact[0]) {
      return contact[0].name.formatted_name
        ? contact[0].name.formatted_name
        : "";
    }
  }
  resolveContactEmail(contact) {
    if (typeof contact === "string") {
      const newContact = JSON.parse(contact);
      if (newContact.email) {
        return newContact.email;
      }
    } else if (contact.length && contact[0]) {
      if (contact[0].emails && contact[0].emails[0]) {
        return contact[0].emails[0].email ? contact[0].emails[0].email : "";
      }
    }
  }
  resolveContactPhone(contact) {
    if (typeof contact === "string") {
      const newContact = JSON.parse(contact);
      if (newContact.phoneNumber) {
        return newContact.phoneNumber;
      }
    } else if (contact.length && contact[0]) {
      if (contact[0].phones && contact[0].phones[0]) {
        return contact[0].phones[0].phone ? contact[0].phones[0].phone : "";
      }
    }
  }
  downloadPdf(base64) {
    var a = document.createElement("a");
    a.href = base64;
    a.download = "file.pdf";
    a.click();
  }
}
