










































































import { Component, Prop } from "vue-property-decorator";
import { EventBus } from "../../services/eventBus";
import Request from "../../services/request";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import { IUserAgent } from "../../types/chat/Chat-userAgent";
import {IUser} from "../../types/User";

@Component
export default class TransferDialog extends Request {
  @Prop() readonly user: IUser;
  @Prop() conversation: IAttendencyList;
  @Prop() loadData: (item) => void;
  @Prop() readonly attendency: any;
  @Prop() readonly type: boolean;

  dialog = false;
  userList: any = [];
  queueList: any = [];
  queue: number = null;
  agente: number = null;
  userAgent: IUserAgent = {};

  async mounted() {
    const resultUser = await this.get(this.paths.userByCompanyPath);
    this.userList = resultUser[0];

    const resultQueue = await this.get(this.paths.queuePath);
    const queue = resultQueue[0];

    const queueUserAgente = await this.get(this.paths.queueUserAgent);

    this.queueList = queueUserAgente[0].filter((queueUserAgente) => {
      return queue.map((queue) => queue.id === queueUserAgente.queueId);
    });
  }
  async getRoom(conversation) {
    this.userAgent = await this.get(this.paths.userAgentPath);
    const userAgent = this.userAgent[0].find((i) => i.userId === this.user.id);

    const entity = {
      userAgentId: userAgent.id,
      queueId: this.queue,
      attendencyId: conversation.attendency.id,
      editorUserId: this.user.id,
      attendencyStatus: 6,
    };
    const result = await this.put(
      this.paths.closureAttendencyPath + conversation.id,
      entity
    );
    this.agente = null;
    this.queue = null;
    this.dialog = false;
    EventBus.$emit("update-list", conversation);
    this.loadData({ item: this.attendency });
    return result;
  }

  get missingQueue() {
    return !this.queue;
  }
  get missingUser() {
    return !this.agente;
  }
  get saveDisabled() {
    return this.missingQueue && this.missingUser;
  }
}
