var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({ always: false }),expression:"{ always: false }"}],key:_vm.rerender,ref:"scrollDiv",staticClass:"chat-room DivToScroll DivWithScroll",attrs:{"id":"backgroundImageChat"}},_vm._l((_vm.newConversation.messages),function(message,index){return _c('div',{key:index,staticClass:"d-flex align-center mb-5 scroll main-part-message",class:[
      _vm.fromMe[message.userName] ? 'fromMe' : '',
      message.id ? 'justify-center' : '' ]},[(message.typeMessage)?_c('v-avatar',{staticClass:"mb-3",attrs:{"size":"50"}},[_c('v-img',{attrs:{"src":_vm.fromMe[message.userName] ? _vm.profileUserAvatar : _vm.profileUserAvatar}})],1):_vm._e(),(message.typeMessage)?_c('span',{staticClass:"chat-content px-4 chat-container",class:[_vm.fromMe[message.userName] ? 'chat-right' : 'chat-left'],attrs:{"id":message._id}},[(message.typeMessage === 'text')?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.resolveMensageText(message.text || message.message))}}),(_vm.fromMe[message.userName])?_c('div',{staticClass:"icon-and-span"},[(message.messageId)?_c('v-icon',{staticClass:"icon-checked",attrs:{"color":message.iconSent && message.iconSent.color
                ? message.iconSent.color
                : '#515354'}},[_vm._v(" "+_vm._s(message.iconSent && message.iconSent.icon ? message.iconSent.icon : "mdi-clock")+" ")]):_vm._e()],1):_vm._e()]):(message.typeMessage === 'image')?_c('div',{staticClass:"received-img",on:{"click":function($event){message.mediaBase64
            ? _vm.openDialogImage(message.mediaBase64)
            : _vm.openDialogImage(message.message)}}},[_c('v-img',{attrs:{"src":message.mediaBase64 || message.message,"width":"20rem"}}),(_vm.fromMe[message.userName])?_c('div',{staticClass:"icon-and-span"},[(message.messageId)?_c('v-icon',{staticClass:"icon-checked",attrs:{"color":message.iconSent && message.iconSent.color
                ? message.iconSent.color
                : '#515354'}},[_vm._v(" "+_vm._s(message.iconSent && message.iconSent.icon ? message.iconSent.icon : "mdi-clock")+" ")]):_vm._e()],1):_vm._e()],1):(message.typeMessage === 'video')?_c('div',{staticClass:"received-img"},[_c('video',{attrs:{"width":"320","height":"240","controls":""}},[_c('source',{attrs:{"src":message.mediaBase64 || message.message,"type":"video/mp4"}}),_c('source',{attrs:{"src":message.mediaBase64 || message.message,"type":"video/ogg"}})]),(_vm.fromMe[message.userName])?_c('div',{staticClass:"icon-and-span"},[(message.messageId)?_c('v-icon',{staticClass:"icon-checked",attrs:{"color":message.iconSent && message.iconSent.color
                ? message.iconSent.color
                : '#515354'}},[_vm._v(" "+_vm._s(message.iconSent && message.iconSent.icon ? message.iconSent.icon : "mdi-clock")+" ")]):_vm._e()],1):_vm._e()]):(message.typeMessage === 'audio')?_c('div',{staticStyle:{"height":"3rem"}},[_c('audio',{attrs:{"src":message.mediaBase64 || message.message,"controls":""}}),(_vm.fromMe[message.userName])?_c('div',{staticClass:"icon-and-span-audio"},[(message.messageId)?_c('v-icon',{staticClass:"icon-checked",attrs:{"color":message.iconSent && message.iconSent.color
                ? message.iconSent.color
                : '#515354'}},[_vm._v(" "+_vm._s(message.iconSent && message.iconSent.icon ? message.iconSent.icon : "mdi-clock")+" ")]):_vm._e()],1):_vm._e()]):(message.typeMessage === 'document')?_c('div',{staticStyle:{"height":"3rem"}},[_c('div',{staticClass:"adobe-download-button-container"},[_c('button',{staticClass:"adobe-pdf-download",on:{"click":function($event){return _vm.downloadPdf(message.message)}}},[_c('table',[_c('tr',[_vm._m(0,true),_c('td',[_c('p',[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t("download pdf")))])])])])])])]),(_vm.fromMe[message.userName])?_c('div',{staticClass:"icon-and-span-audio"},[(message.messageId)?_c('v-icon',{staticClass:"icon-checked",attrs:{"color":message.iconSent && message.iconSent.color
                ? message.iconSent.color
                : '#515354'}},[_vm._v(" "+_vm._s(message.iconSent && message.iconSent.icon ? message.iconSent.icon : "mdi-clock")+" ")]):_vm._e()],1):_vm._e()]):(message.typeMessage === 'contacts')?_c('div',{staticClass:"received-img"},[_c('div',{class:[_vm.fromMe[message.userName] ? 'card-right' : 'card-left']},[_c('div',{class:[
              _vm.fromMe[message.userName] ? 'banner-right' : 'banner-left' ]},[_c('v-avatar',{staticClass:"mb-3",attrs:{"size":"35"}},[_c('v-img',{attrs:{"src":_vm.fromMe[message.userName]
                    ? _vm.profileUserAvatar
                    : _vm.profileUserAvatar}})],1)],1),_c('div',{staticClass:"container-contact-res"},[_c('h3',{staticClass:"h3-contact"},[_vm._v(" "+_vm._s(_vm.resolveContactName(message.message))+" ")]),_c('span',[_vm._v(_vm._s(_vm.resolveContactEmail(message.message)))]),_c('span',[_vm._v(" "+_vm._s(_vm.resolveContactPhone(message.message)))])])]),(_vm.fromMe[message.userName])?_c('div',{staticClass:"icon-and-span"},[(message.messageId)?_c('v-icon',{staticClass:"icon-checked",attrs:{"color":message.iconSent && message.iconSent.color
                ? message.iconSent.color
                : '#515354'}},[_vm._v(" "+_vm._s(message.iconSent && message.iconSent.icon ? message.iconSent.icon : "mdi-clock")+" ")]):_vm._e()],1):_vm._e()]):(message.typeMessage === 'location')?_c('div',{staticClass:"received-img",class:[_vm.fromMe[message.userName] ? 'chat-right' : 'chat-left']},[_c('div',[_c('GmapMap',{staticStyle:{"width":"280px","height":"190px"},attrs:{"center":{
              lat: message.message.latitude,
              lng: message.message.longitude,
            },"zoom":15,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
                lat: message.message.latitude,
                lng: message.message.longitude,
              },"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = {
                  lat: message.message.latitude,
                  lng: message.message.longitude,
                }}}})],1)],1)]):_vm._e()]):_vm._e(),(message.id)?_c('div',{staticClass:"justify-center"},[_c('v-alert',{attrs:{"dense":"","text":"","color":"default"}},[_c('span',{staticClass:"historicInfo"},[_vm._v(_vm._s(_vm.resolveBadge(message)))])])],1):_vm._e()],1)}),0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('img',{attrs:{"src":"http://www.clker.com/cliparts/b/2/c/b/13534493561426065224Adobe%20Icon.svg.med.png"}})])}]

export { render, staticRenderFns }