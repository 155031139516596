













































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { mapGetters } from "vuex";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import { IUser } from "../../types/User";
 
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class PendingAttendency extends Request {
  @Prop() readonly user: IUser;
  @Prop() readonly conversation: IAttendencyList;
  @Prop() readonly loadData: () => void;
  @Prop() readonly type: boolean;
  getUser: any;

  dialog: boolean = false;

  attendencyQueueList = [];
  itemsPerPage: number = 300;
  async mounted() {
    const user = this.getUser();
    const result = await this.get(
      this.paths.attendencyQueueUserAgent +
        `?userAgentId=${user.userAgent.id}&skip=${1}&take=${this.itemsPerPage}`
    );
    this.attendencyQueueList = result.result;
  }
  async onSave(conversation) {
    const result = await this.patch(
      this.paths.attendencyPath + conversation.attendency.id,
      {
        attendencyStatus: 7,
        editorUserId: this.user.id,
      }
    );
    this.loadData();
    this.dialog = false;
    this.$emit("clear-currenct-attendency", {});
    this.$emit("close-historic-dialog", false);
    return result;
  }
}
