











































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import {  ICompany } from "../../types/Company";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mask } from "vue-the-mask";
import axios from "axios";
import { mapGetters } from "vuex";
import { IPlan } from "../../types/Plan";
import {IUser} from "../../types/User";
@Component({
  directives: { mask },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class CompanyInput extends Request {
  @Prop() entity: ICompany;
  @Prop() list: ICompany[];
  @Prop() loadData: () => void;
  @Prop() planList: IPlan[];
  @Prop() productId: number;
  @Prop() productName: string;
  header: string = translate("title.company");
  selectedPlan: IPlan[] = [];

  dialog: boolean = false;
  localEntity: ICompany = {};
  listProducts: any = {};

  getUser: () => IUser;
  listPlans: any = [];
  payDate: string = null;
  tab: number = 0;
  items = [`${translate("title.company")}`, `${translate("plan")}`];
  editorUserId: number = null;
  onSave() {
    this.dialog = false;
    return this.newList();
  }
  load() {
    if (this.entity.id) {
      this.localEntity = {
        ...this.entity,
      };
    }
  }
  async newList() {
    this.selectedPlan = [
      {
        planId: this.listProducts?.plan?.id
          ? this.listProducts?.plan?.id
          : this.listProducts?.plan,
        productId: this.productId,
        name: this.productName,
        payDate: this.payDate,
      },
    ];
    this.localEntity.plan = this.selectedPlan;
    this.localEntity.editorUserId = this.editorUserId;
    const result = await this.post(
      this.paths.companySavePath,
      this.localEntity
    );

    this.loadData();
    1;
    if (result.message) {
      this.$message?.errorMessage(`${translate(`error.${result.message}`)}`);
    } else {
      this.$message?.successMessage(
        `${translate("message.savedSuccessfully")}`
      );
    }

    this.tab = 0;
    return result;
  }
  async mounted() {
    const user = this.getUser();
    this.editorUserId = user.id;
    this.load();
    if (
      this.localEntity.companyProductPlan &&
      this.localEntity.companyProductPlan.length
    ) {
      this.payDate = this.localEntity.companyProductPlan[0].payDate;
    }
    if (this.entity.id && this.entity.companyProductPlan.length) {
      this.listProducts.plan = this.planList.find(
        (plan) => plan.id == this.localEntity?.companyProductPlan[0]?.planId
      );
    }
  }
  async searchCep() {
    if (this.localEntity.zipCode.length >= 8) {
      const result = await axios.get(
        `https://viacep.com.br/ws/${this.localEntity.zipCode}/json/`
      );
      this.localEntity.district = result.data.bairro;
      this.localEntity.zipCode = result.data.cep;
      this.localEntity.address = result.data.logradouro;
      this.localEntity.city = result.data.localidade;
      this.localEntity.state = result.data.uf;
    }
  }
  get missingSocialName() {
    return !this.localEntity.socialName;
  }
  get missingOfficialName() {
    return !this.localEntity.officialName;
  }
  get missingCnpj() {
    return !this.localEntity.cnpj;
  }
  get missingCode() {
    return !this.localEntity.code;
  }
  get nextDisabled() {
    return (
      this.missingSocialName || this.missingOfficialName || this.missingCnpj
      // this.missingCode
    );
  }
  get missingPlan() {
    return !this.listProducts.plan;
  }
  get missingPayDate() {
    return !this.payDate;
  }
  get isDisabledSave() {
    return this.missingPlan || this.missingPayDate;
  }
}
