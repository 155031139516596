








































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import PersonInput from "./pages/PersonInput.vue";
import EmailInput from "./pages/EmailInput.vue";
import ContactPhone from "./pages/ContactPhoneInput.vue";
import SocialMediaContactInput from "./pages/SocialMediaContactInput.vue";
import ContactOfficialDocInput from "./pages/ContactOfficialDocInput.vue";
import AddressInput from "./pages/AddressInput.vue";
import { mapGetters } from "vuex";

import { IUser } from "../../types/User";
import { Contacts } from "../../types/person/Contacts";
import { IContactEmail } from "../../types/person/IContactEmail";
import { IContactSocialMedias } from "../../types/person/IContactSocialMedia";
import { IContactOfficialDoc } from "../../types/person/IContactOfficialDoc";
import { IContactPhone } from "../../types/person/IContactPhone";

@Component({
  components: {
    PersonInput,
    EmailInput,
    ContactPhone,
    SocialMediaContactInput,
    ContactOfficialDocInput,
    AddressInput,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class StepperT extends Request {
  @Prop() readonly entity: Contacts;
  @Prop() loadData: () => void;
  getUser!: () => IUser;
  user: IUser;
  editorUserId: number = null;
  e1: number = 1;
  dialog: boolean = false;
  person: Contacts = {};
  email: IContactEmail[] = [];
  phone: IContactPhone[] = [];
  socialMedia: IContactSocialMedias[] = [];
  docs: IContactOfficialDoc[] = [];
  steps = [
    { name: `${translate("ppersonalData")}`, step: 1 },
    { name: `${translate("email")}`, step: 2 },
    { name: `${translate("phone")}`, step: 3 },
    { name: `${translate("socialMedia")}`, step: 4 },
    { name: `${translate("document")}`, step: 5 },
    { name: `${translate("address")}`, step: 6 },
  ];
  closeDialog(event: boolean) {
    this.dialog = event;
  }
  nextStep(event: number) {
    this.e1 += event;
  }
  backStep(event: number) {
    this.e1 += event;
  }
  initialStep(value) {
    this.e1 = value;
  }
  getEntity(event) {
    this.person = event;
  }
  personWithEmail(event) {
    this.email = event;
  }
  personWhithPhone(event) {
    this.phone = event;
  }
  personWithMedia(event) {
    this.socialMedia = event;
  }
  personWithDoc(event) {
    this.docs = event;
  }
  personWithAddress(event) {
    this.person = event;
  }
  mounted() {
    this.user = this.getUser();
    this.editorUserId = this.user.id;
  }
  async onSave() {
    this.person.editorUserId = this.editorUserId;
    const result = await this.save(this.paths.personCompletePath, this.person);
    if (!this.person.id) {
      this.person = {};
      this.email = [];
      this.phone = [];
      this.socialMedia = [];
      this.docs = [];
    }
    this.e1 = 1;
    this.dialog = false;
    this.loadData();
    return result;
  }
}
