









































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import axios from "axios";
import { ICompany } from "../../../types/Company";
import { IPlan } from "../../../types/Plan";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { translate } from "../../../plugins/i18n/index";
@Component({
  components: { PageHeader },
})
export default class ConfigurationTabs extends Request {
  @Prop() readonly companyId: number;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-office-building", 
    titlePage: `${translate("title.company")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  localEntity: ICompany = {};
  plans: IPlan[] = [];
  async onSave() {
    this.localEntity.plan = this.plans;
    const result = await this.post(
      this.paths.companySavePath,
      this.localEntity
    );

    if (result.message) {
      this.$message?.errorMessage(`${translate(`error.${result.message}`)}`);
    } else {
      this.$message?.successMessage(`${translate("saved")}`);
    }
  }

  async mounted() {
    const result = await this.get(this.paths.companyPath + this.companyId);

    this.localEntity = result;

    this.plans = await this.get(
      this.paths.companyProductPlanGetPath + this.companyId
    );
  }
  async searchCep() {
    if (this.localEntity.zipCode.length >= 8) {
      const result = await axios.get(
        `https://viacep.com.br/ws/${this.localEntity.zipCode}/json/`
      );
      this.localEntity.district = result.data.bairro;
      this.localEntity.zipCode = result.data.cep;
      this.localEntity.address = result.data.logradouro;
      this.localEntity.city = result.data.localidade;
      this.localEntity.state = result.data.uf;
    }
  }
}
