




















































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import ConfirmDelete from "../../../components/ConfirmDelete.vue";

import {
  Contacts,
  ISocialMedia,
} from "../../../types/person/Contacts";
import { IContactSocialMedias } from "../../../types/person/IContactSocialMedia";
@Component({
  components: { ConfirmDelete },
})
export default class SocialMediaContactInput extends Request {
  @Prop() entity: Contacts;
  @Prop() person: Contacts;
  @Prop() editorUserId: number;
  nickname: string = "";
  deletePath: string = "";
  socialMediaTypeId: number = null;
  icon: string = "";
  phoneNumberAssociated: string = "";
  emailAssocieted: string = "";
  list: IContactSocialMedias[] = [];
  closeDialog() {
    this.$emit("closeDialog", false);
  }
  async nextStep() {
    this.list.map((media) => {
      media.editorUserId = this.editorUserId;
      media.isMain === true ? (media.isMain = true) : (media.isMain = true);
    });
    this.person.personContactSocialMedias = this.list;

    this.$emit("person-with-media", this.person);
    return this.$emit("nextStep", 1);
  }
  backStep() {
    this.$emit("backStep", -1);
  }
  listSocialMedia: ISocialMedia[] = [];

  async mounted()  {
    const result = await this.get(this.paths.socialMediaTypePath);

    this.listSocialMedia = result[0];

    if (this.entity.personContactSocialMedias) {
      this.entity.personContactSocialMedias.map((item) => {
        this.list.push(item.contactSocialMedia);
      });
    }
  }

  addComponent() {
    this.list.push({
      nickname: this.nickname,
      socialMediaTypeId: this.socialMediaTypeId,
    });
  }
  handleClick(indexElement, model) {
    for (let index = 0; index < this.list.length; index++) {
      if (model === true) {
        this.list[index].isMain = false; //2
        this.list[indexElement].isMain = true;
      } else {
        this.list[index].isMain = null; //1
        this.list[indexElement].isMain = true;
      }
    }
  }
}
