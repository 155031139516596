











































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { translate } from "../../plugins/i18n";
import Request from "../../services/request";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import ContactDialog from "./ContactDialog.vue";
@Component({
  components: { ContactDialog },
})
export default class ActiveDialog extends Request {
  @Prop() conversation: IAttendencyList;
  @Prop() fromMe: boolean;
  @Prop() rerender: number;
  @Prop() profileUserAvatar: string;

  openDialogImage(srcImageToOpen: string) {
    this.$emit("upload-src", srcImageToOpen);
  }

  get newConversation() {
    return this.conversation;
  }
  resolveContactName(contact) {
    if (typeof contact === "string") {
      const newContact = JSON.parse(contact);
      if (newContact.firstName) {
        return (
          newContact.firstName +
          `${newContact.lastName ? newContact.lastName : ""}`
        );
      }
    } else if (contact.length && contact[0]) {
      return contact[0].name.formatted_name
        ? contact[0].name.formatted_name
        : "";
    }
  }
  resolveContactEmail(contact) {
    if (typeof contact === "string") {
      const newContact = JSON.parse(contact);
      if (newContact.email) {
        return newContact.email;
      }
    } else if (contact.length && contact[0]) {
      if (contact[0].emails && contact[0].emails[0]) {
        return contact[0].emails[0].email ? contact[0].emails[0].email : "";
      }
    }
  }
  resolveContactPhone(contact) {
    if (typeof contact === "string") {
      const newContact = JSON.parse(contact);
      if (newContact.phoneNumber) {
        return newContact.phoneNumber;
      }
    } else if (contact.length && contact[0]) {
      if (contact[0].phones && contact[0].phones[0]) {
        return contact[0].phones[0].phone ? contact[0].phones[0].phone : "";
      }
    }
  }
  downloadPdf(base64) {
    var a = document.createElement("a");
    a.href = base64;
    a.download = "file.pdf";
    a.click();
  }
  resolveMensageText(text) {
    text = text.replace(/\*/g, "");
    if (text.includes(":")) {
      const modifiedText = text.replace(new RegExp(":", "g"), ":" + "<br>");
      return modifiedText;
    }
    return text;
  }
  resolveBadge(message) {
    if (message.queueId && !message.userAgentId) {
      return translate("attendencyTransferredTo") + message.queue.name;
    } else if (!message.queueId && message.userAgentId) {
      return translate("attendencyTransferredToAgent") + message.userAgent.name;
    } else {
      return `${translate("attendencyTransferredToAgent")}  ${
        message.userAgent.name
      } ${translate("andQueue")}  ${message.queue.name}`;
    }
  }
}
