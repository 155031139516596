



























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import ConfirmDelete from "../../../components/ConfirmDelete.vue";
import axios from "axios";
import {  Contacts, IZipCode } from "../../../types/person/Contacts";
import { IContactAddress } from "../../../types/person/IContactAddress";

@Component({
  components: { ConfirmDelete },
})
export default class AddressInput extends Request {
  @Prop() person: Contacts;
  @Prop() entity: Contacts;
  @Prop() loadData: () => void;
  @Prop() onSave: () => void;
  @Prop() readonly editorUserId: number;
  locationReference: string = "";
  district: string = "";
  deletePath:string = "";
  postalCode: string = "";
  locationName: string = "";
  complement: string = "";
  locationNumber: number = null;
  active: boolean = true;
  zipCodeList: IZipCode[] = [];
  state: string = "";
  isMain: boolean;
  list: IContactAddress[] = [];
  closeDialog() {
    this.$emit("closeDialog", false);
  }
  async nextStep() {
    this.list.map((address) => {
      address.editorUserId = this.editorUserId;
      address.isMain === true
        ? (address.isMain = true)
        : (address.isMain = true);
    });
    this.person.personContactAddress = this.list;
    this.$emit("person-with-address", this.person);
    this.$emit("nextStep", 0);
    return this.onSave();
  }
  backStep() {
    this.$emit("backStep", -1);
  }

  handleClick(indexElement, model) {
    for (let index = 0; index < this.list.length; index++) {
      if (model === true) {
        this.list[index].isMain = false; //2
        this.list[indexElement].isMain = true;
      } else {
        this.list[index].isMain = null; //1
        this.list[indexElement].isMain = true;
      }
    }
  }
  addComponent(): void {
    this.list.push({
      locationReference: this.locationReference,
      district: this.district,
      postalCode: this.postalCode,
      complement: this.complement,
      locationName: this.locationName,
      state: this.state,
      locationNumber: this.locationNumber,
      active: this.active,
      isMain: this.isMain,
    });
  }

  deleteItem(index: number): void {
    if (index > 0) {
      this.list.splice(index, 1);
    }
  }
  async searchCep() {
    for (let index = 0; index < this.list.length; index++) {
      if (this.list[index].postalCode.length >= 8) {
        const result: any = await axios.get(
          `https://viacep.com.br/ws/${this.list[index].postalCode}/json/`
        );
        (this.list[index].district = result.data.bairro),
          (this.list[index].postalCode = result.data.cep),
          (this.list[index].locationName = result.data.logradouro),
          (this.list[index].neighborhood = result.data.localidade),
          (this.list[index].locationReference = result.data.uf);
      }
    }
  }
  async mounted() {
    const result = await this.get(this.paths.zipCodePath);
    this.zipCodeList = result[0];

    if (this.entity.personContactAddress) {
      this.entity.personContactAddress.map((item) => {
        this.list.push(item.contactAddress);
      });
    }
  }
}
