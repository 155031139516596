






















































import { Component } from "vue-property-decorator";
import CompanyInput from "./CompanyInput.vue";
import { ICompany } from "../../types/Company";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ConfirmDelete from "../../components/IsDeleteCompany.vue";
import { Breadcrumb } from "../../types/breadcrumb";
import { HeaderItem } from "../../types/headeritem";
import { IPlan } from "../../types/Plan";

@Component({
  components: {
    CompanyInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class CompanyList extends Request {
  deletepPath: string = paths.companyDeletePath;
  page = {
    title: `${translate("title.company")}`,
    icon: null,
  };
  productName: string = "";
  productId: number = null;
  planList: IPlan[] = [];
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.company")}`,
      disabled: true,
    },
  ];
  header: HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "socialName" },
    {
      text: `${translate("usualCompanyName")}`,
      align: "center",
      value: "officialName",
    },

    { text: `${translate("cnpj")}`, align: "center", value: "cnpj" },

    {
      text: `${translate("plan")}`,
      align: "center",
      value: "planName",
    },

    {
      text: `${translate("payDate")}`,
      align: "center",
      value: "payDateValue",
    },

    { text: `${translate("active")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-office-building",
    titlePage: `${translate("title.companies")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  path: string = "";
  list: ICompany[] = [];

  async loadData() {
    this.path = this.paths.companyListPath;
    const result = await this.get(this.paths.companyListPath);

    if (result) {
      this.list = result["result"];
      this.list.forEach((company) => {
        if (company.isDeleted === true) {
          this.list = this.list.filter((item) => item.isDeleted != true);
        }
      });

      this.list.forEach((company) => {
        if (company.companyProductPlan && company.companyProductPlan.length) {
          company.companyProductPlan.map((companyProductPlan) => {
            company.payDateValue = companyProductPlan.payDate;
            company.planName = companyProductPlan.plan.name;
          });
        }
        company.cnpj = company.cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      });
    }
    const plans = await this.get(this.paths.heppyInvoicePlans);
    this.planList = plans[0];
    const products = await this.get(this.paths.heppyInvoiceProduct);

    const product = products[0].find((product) => product.scope === "HEP");
    this.productId = product.id;
    this.productName = product.name;
  }

  async mounted() {
    this.loadData();
  }
}
