










































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { mapGetters } from "vuex";
import {IUser} from "../../types/User";
import { IAttendencyList } from "../../types/chat/chat-attendency";
import { IDataUser } from "../../types/chat/data-area";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class DataArea extends Request {
  @Prop() readonly type: string;
  @Prop() readonly conversation: IAttendencyList;
  @Prop() drawerData: boolean;
  drawer: boolean = false;
  personContactPhones: any = {};
  localEntity: IDataUser = {};
  getUser!: () => IUser;
  user:IUser= {};
  load(): void {
    if (this.conversation.contact) {
      this.localEntity = { ...this.conversation.contact };
    }
  }

  mounted() {
    this.load();
    const user = this.getUser();
    this.user = user;
    this.localEntity.personContactEmails = [];
    this.localEntity.personContactPhones = [];
  }   
  async onSave()  {
    this.localEntity.personContactEmails.push({
      alias: "P",
      email: this.localEntity.email,
      isMain: true,
      editorUserId: this.user.id,
    });
    this.localEntity.personContactPhones.push(this.personContactPhones);
    this.localEntity.editorUserId = this.user.id;
    const result = await this.save(
      this.paths.personCompletePath,
      this.localEntity
    );
    return result;
  }
  updateDrawerData() {
    this.drawerData === true
      ? this.$emit("drawerData", false)
      : this.$emit("drawerData", true);
  }
  onInput(_formattedNumber: any, { number, valid, country }) {
    valid;

    if (number.input.length > 2) {
      if (this.localEntity.phone) {
        this.personContactPhones.phoneNumber = this.localEntity.phone;
        this.personContactPhones.isMain = true;
        this.personContactPhones.editorUserId = this.user.id;
        this.personContactPhones.ddi = "+" + country.dialCode;
        this.personContactPhones.cityPhoneCode =
          this.localEntity.phone.substring(1, 3);
      }
    }
  }
}
