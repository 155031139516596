
















































import { Component } from "vue-property-decorator";
import Request from "../../../services/request";
import { mapGetters } from "vuex";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import { translate } from "../../../plugins/i18n";
import { IUser } from "../../../types/User";
import { IConfiguration } from "../../../types/configuration";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
  components: { PageHeader },
})
export default class PendingAttendency extends Request {
  pageHeader: TPageHeader = {
    mainIcon: "mdi-notebook",
    titlePage: `${translate("title.chatConfiguration")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  localEntity: IConfiguration = {};
  getUser!: () => IUser;
  user:IUser= {};
  editorUserId: number;
  isAgentIdentified: number = null;

  async mounted() {
    this.user = this.getUser();
    this.editorUserId = this.user?.id;

    const result = await this.get(
      this.paths.configurationPath + this.user?.companyId
    );

    this.localEntity = result;
    this.isAgentIdentified = this.localEntity.editorUserId;
  }
  async onSave() {
    this.localEntity.editorUserId = this.editorUserId;
    this.localEntity.editorUserId = this.isAgentIdentified;
    const result = await this.patch(
      this.paths.configurationPath + this.localEntity.id,
      this.localEntity
    );

    return result;
  }
}
