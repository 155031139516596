




















import { Component } from "vue-property-decorator";
import Request from "../../services/request";
import CompanyData from "./tabs/CompanyData.vue";
import { mapGetters } from "vuex";
import { translate } from "../../plugins/i18n";
import ProfileList from "../profile/ProfileList.vue";
import PlanConfigList from "../planConfig/PlanConfigList.vue";
import Config from "./tabs/Config.vue";
import {IUser} from "../../types/User";
interface Item {
  id: number;
  title: string;
  icon: string;
}
@Component({
  components: { CompanyData, ProfileList, PlanConfigList, Config },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class ConfigurationTabs extends Request {
  tab: number = null;
  getUser!: () => IUser;
  user:IUser= {};
  items: Item[] = [
    { id: 1, title: `${translate("companyData")}`, icon: "mdi-domain" },
    { id: 2, title: `${translate("permissions")}`, icon: "mdi-shield-account" },
    { id: 3, title: `${translate("plan")}`, icon: "mdi-notebook-multiple" },
    { id: 4, title: `${translate("chatCustomization")}`, icon: "mdi-cog" },
  ];

  async mounted() {
    this.user = this.getUser();
  }
}
