

























































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import ConfirmDelete from "../../../components/ConfirmDelete.vue";
import {  Contacts } from "../../../types/person/Contacts";
import { IContactEmail } from "../../../types/person/IContactEmail";
interface EmailType {
  id: string;
  name: string;
}

@Component({
  components: { ConfirmDelete },
})
export default class EmailInput extends Request {
  @Prop() entity: Contacts;
  @Prop() person: Contacts;
  @Prop() editorUserId: number;

  list: IContactEmail[] = [];
  localEntity: IContactEmail[] = [];
  alias: string;
  email: string;
  switch: boolean;
  isMain: boolean;

  typeEmail: EmailType[] = [
    {
      id: "C",
      name: "Comercial",
    },
    {
      id: "P",
      name: "Particular",
    },
  ];
  deletePath = "";
  async nextStep() {
    this.list.map((email) => {
      email.editorUserId = this.editorUserId;
      email.isMain === true ? (email.isMain = true) : (email.isMain = true);
      email.valid = true;
      email.confirmed = 0;
    });
    this.person.personContactEmails = this.list;

    this.$emit("person-with-email", this.person);
    this.$emit("nextStep", 1);
  }
  closeDialog() {
    this.$emit("closeDialog", false);
  }
  backStep() {
    this.$emit("backStep", -1);
  }
  addComponent() {
    this.list.push({
      alias: this.alias,
      email: this.email,
      isMain: this.isMain,
    });
  }
  deleteItem(index: number) {
    if (index >= 0) {
      this.remove(this.deletePath, index);
      this.list.splice(index, 1);
    }
  }

  handleClick(indexElement, model) {
    for (let index = 0; index < this.list.length; index++) {
      if (model === true) {
        this.list[index].isMain = false; //2
        this.list[indexElement].isMain = true;
      } else {
        this.list[index].isMain = null; //1
        this.list[indexElement].isMain = true;
      }
    }
  }
  async mounted() {
    // if (this.list.length <= 0) {
    //   this.list.push({
    //     alias: this.alias,
    //     email: this.email,
    //     isMain: this.isMain,
    //   });
    // }
    if (this.entity.personContactEmail) {
      this.entity.personContactEmail.map((item) => {
        this.list.push(item.contactEmail);
      });
    }
  }
}
