












































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import TransferDialog from "./TransferDialog.vue";
import CloseDialog from "./ClosingDialog.vue";
import { IAttendencyList } from "../../types/chat/chat-attendency";
@Component({
  components: {
    TransferDialog,
    CloseDialog,
  },
})
export default class ActiveDialog extends Request {
  @Prop() readonly conversation: IAttendencyList;
  @Prop() drawerDocs: boolean;
  drawer: boolean = false;
  openImage(src: string) {
    this.$emit("overlay", true);
    this.$emit("selectedImage", src);
  }
  updateDrawerDocs() {
    this.drawerDocs === true
      ? this.$emit("drawerDocs", false)
      : this.$emit("drawerDocs", true);
  }
}
