var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.type == 'icon')?_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-transfer-right ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","title":_vm.$t('transferService'),"color":"#006BFD"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-transfer-right ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("transferService")))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":"","right":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-select',{attrs:{"outlined":"","items":_vm.queueList,"label":_vm.$t('attendencyQueue'),"item-text":"queue.name","item-value":"queue.id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.queue.name)+" ")]}}]),model:{value:(_vm.queue),callback:function ($$v) {_vm.queue=$$v},expression:"queue"}}),_c('v-select',{attrs:{"outlined":"","items":_vm.userList,"label":_vm.$t('users'),"item-text":"firstName","item-value":"id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" ")]}}]),model:{value:(_vm.agente),callback:function ($$v) {_vm.agente=$$v},expression:"agente"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","disabled":_vm.saveDisabled},on:{"click":function($event){return _vm.getRoom(_vm.conversation)}}},[_vm._v(" "+_vm._s(_vm.$t("yes"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("not"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }