




































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import ConfirmDelete from "../../../components/ConfirmDelete.vue";
import { IContactOfficialDoc } from "../../../types/person/IContactOfficialDoc";

@Component({
  components: { ConfirmDelete },
})
export default class ContactOfficialDocInput extends Request {
  @Prop() person: any;
  @Prop() entity: any;
  @Prop() readonly editorUserId;
  list: IContactOfficialDoc[] = [];
  name: string = "";
  deletePath = "";
  description: string = "";
  docNumber: number = null;
  regulatoryBody: string = "";
  docImage: any = null;
  active: boolean = true;
  officialDocsList: any = [];
  officialDocTypeId: number = null;
  s3Result: any = {};
  loading: boolean = false;
  closeDialog() {
    this.$emit("closeDialog", false);
  }
  async nextStep() {
    this.list.map((docs) => {
      docs.editorUserId = this.editorUserId;
      docs.isMain === true ? (docs.isMain = true) : (docs.isMain = true);
    });
    this.person.personContactOfficialDocs = this.list;
    this.$emit("person-with-doc", this.person);
    return this.$emit("nextStep", 1);
  }
  backStep() {
    this.$emit("backStep", -1);
  }

  addComponent() {
    this.list.push({
      name: this.name,
      description: this.description,
      docNumber: this.docNumber,
      regulatoryBody: this.regulatoryBody,
      docImage: this.docImage,
      active: this.active,
      officialDocTypeId: this.officialDocTypeId,
    });
  }
  async mounted() {
 
    const result = await this.get(this.paths.contactOfficialTypePath);
    this.officialDocsList = result[0];

    if (this.entity.personContactOfficialDoc) {
      this.entity.personContactOfficialDoc.map((item) => {
        this.list.push(item.contactOfficial);
      });
    }
  }
  async savePhoto(photo) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      await this.createFile(event.target.result);
      this.list.map((docs) => {
        docs.docImage = this.s3Result.s3.body;
      });
    };
    reader.readAsDataURL(photo);
  }
  async createFile(file) {
    this.loading = true;

    const FormData = require("form-data");
    const data = new FormData();
    data.append("file", file);

    const result = await this.post(this.paths.storagePath, data);

    this.loading = false;
    return (this.s3Result = result);
  }
}
